import { useDocumentViewerContext } from '../../../contexts/DocumentViewerContextProvider';
import { OLD_BLANK_REPORT_JSON, NEW_BLANK_REPORT_JSON } from '../../../consts/reportConstants';
import createViewport from '../utils/createViewport';
import { BLANK_REPORT_ID } from '../../../consts/consts';
import useCheckAccess from '../../../hooks/useCheckAccess';
import useBestMatchTemplateForStudy from './useBestMatchTemplateForStudy';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { useDocumentViewerPermissionsContext } from '../../../contexts/DocumentViewerPermissionsContextProvider';

const useAddBlankReport = () => {
	const { setBlankReport, blankReport, setViewports, setAppliedPageSetup } = useDocumentViewerContext();
	const { getBestMatchTemplate } = useBestMatchTemplateForStudy();
	const { PRELIMINARY_REPORT_PERMISSION } = useDocumentViewerPermissionsContext();
	const reportCreatePermission = PRELIMINARY_REPORT_PERMISSION?.CREATE;
	const galaxyDvRemoveBlankReportData = useBooleanFlagValue('galaxy-dv-remove-blank-report-data');

	const BLANK_REPORT_JSON = galaxyDvRemoveBlankReportData ? NEW_BLANK_REPORT_JSON : OLD_BLANK_REPORT_JSON;

	const { hasFullAccess } = useCheckAccess();

	const addBlankReport = async force => {
		if (!hasFullAccess || !reportCreatePermission) return;
		/* prevent creation more than 1 blank report */
		if (blankReport && !force) {
			return;
		}

		await getBestMatchTemplate(true);

		setBlankReport({ ...BLANK_REPORT_JSON });
		const blankViewport = createViewport({ documentId: BLANK_REPORT_ID });
		setViewports([blankViewport]);
		setAppliedPageSetup({});
		//document.querySelector('.study-navigator-content') is the Study-NavigatorScrollableContent
		//container.querySelectorAll('.study-category-title')[0] is a Preliminary report text in current study category
		const container = document?.querySelector('.study-navigator-content');
		const element = container?.querySelectorAll('.study-category-title')?.[0];

		//Scroll to Preliminary report text. 58px is the height of Study-Header which will came on top of Preliminary Text
		if (container && element) {
			const scrollTop = element.offsetTop - 58;
			container.scrollTo({
				top: scrollTop,
				behavior: 'smooth',
			});
		}
	};

	return {
		addBlankReport,
	};
};

export default useAddBlankReport;
