/* eslint-disable consistent-return */
// eslint-disable-next-line import/no-relative-packages
import SignalRManager from '../../../../../../../utils/signalR';
import { logError, logInfo, logWarn } from '@worklist-2/core/src';
import { getStudyInstanceUID } from '../../utils/utils';
import axios from 'axios';

const initializeImageViewerHubManager = async ({ config, subscriptionDataLoader, studies, callback }) => {
	if (!studies?.length) {
		logWarn('No studies found. Skipping initialization of Image viewer hub manager.');
		return;
	}

	const studiesToListen = studies.map(study => ({
		studyUid: getStudyInstanceUID(study),
		internalStudyId: study.internalStudyId,
		organizationId: study.managingOrganizationId,
	}));

	const subscriptionId = localStorage.getItem('sessionId');

	const sRmanager = new SignalRManager(config, async data => {
		if (data && data?.reasonCode?.text === 'iv-operation') {
			createNewSubscription(subscriptionDataLoader, subscriptionId, studiesToListen)
				.then()
				.catch(err => {
					logError(`IV:Hub`, 'Subscription creation failed', err);
				});
			await callback?.(data);
		} else {
			logInfo(`IV:Hub`, 'IV Hub message received without body', '');
		}
	});

	await createNewSubscription(subscriptionDataLoader, subscriptionId, studiesToListen);
	await sRmanager.connectAPISocket(subscriptionId);
	return sRmanager;
};

const createNewSubscription = async (loader, subscriptionId, studiesToListen) => {
	const subscriptionPayload = {
		criteria: `Task?owner=IV&status=rejected,cancelled,failed,completed,entered-in-error`,
		id: '-1',
		resourceType: 'Subscription',
		status: 'requested',
		reason: 'IVOperation',
		channelType: 'websocket',
		studyListener: studiesToListen,
		extension: [
			{
				url: 'http://www.ramsoft.com/fhir/extension/SubscriptionUID',
				valueString: `${subscriptionId}`,
			},
		],
		channel: {
			type: 'websocket',
			extension: [
				{
					url: 'http://www.ramsoft.com/fhir/StructureDefinition/SubType',
					valueString: 'SignalRWithData',
				},
			],
		},
	};

	const subsresult = await saveData(
		loader,
		{
			resourcetype: 'task',
			type: 'websocket',
			subtype: 'signalr',
		},
		subscriptionPayload
	);

	logInfo(`IV:Hub`, `Listening changes for ${studiesToListen.length} studies. Subscription Id is ${subsresult.id}`);
};

const saveData = async (loader, props, payload) => {
	let saveResult = {};
	try {
		saveResult = await loader.save(props, payload).then(result => result.data);
	} catch (e) {
		logError(`IV:Hub`, `Error saving data`, e);
	}
	return saveResult;
};

const getRunningTaskAsync = async (__config, studies) => {
	try {
		return (await axios.get(`${__config.data_sources.fhir}/task?studylistener=${studies}`))?.data;
	} catch (e) {
		logError(`IV:Hub`, `Error getting data`, e);
		throw e;
	}
};

export { initializeImageViewerHubManager, getRunningTaskAsync, saveData, createNewSubscription };
