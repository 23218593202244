import { HubConnectionBuilder } from '@microsoft/signalr';
import { logInfo, logWarn, logError } from '@worklist-2/core/src';
import axios from 'axios';

export default class SignalRManager {
	config;
	responseDataHandler;
	connection;

	constructor(config, responseDataHandler) {
		this.config = config;
		this.responseDataHandler = responseDataHandler;
	}

	async fetchAccessToken(id, customUrl) {
		try {
			let url =
				customUrl ?? `${this.config.auth.omegaai.auth_api}/token/SignalRConnectionInfo?hub=task&user=${id}`;
			const res = await axios.get(url);
			return res.data;
		} catch (e) {
			logError(`SignalR Hub`, `Error fetching access token`, e);
			throw e;
		}
	}

	createSignalRConnection(signalRUrl, options) {
		return new HubConnectionBuilder().withUrl(signalRUrl, options).build();
	}

	startConnection(connection) {
		connection
			.start()
			.then(() => {
				logInfo(`SignalR Hub`, `Signalr successfully connected!`, '');
			})
			.catch(err => {
				logError(`SignalR Hub`, `Error connecting to signalr`, err);
				setTimeout(() => {
					this.startConnection(connection); // Recursive call
				}, 2000);
			});
	}

	async closeConnection() {
		if (this.connection) {
			try {
				await this.connection.stop();
				this.connection = null;
			} catch (e) {
				logError(`SignalR Hub`, `Error closing connection`, e);
			}
		} else {
			logInfo(`SignalR Hub`, `No active connection to close`, '');
		}
	}

	async connectAPISocket(subscriptionId, customUrl) {
		try {
			// 2. get accession token from auth
			const tokenData = await this.fetchAccessToken(subscriptionId, customUrl);
			let url = tokenData.url;

			const options = {
				accessTokenFactory: () => tokenData.accessToken,
			};
			// 3. Create signalR connection
			let connection = this.createSignalRConnection(url, options);
			let receivedResponse = false;
			connection.on('taskUpdated', data => {
				this.responseDataHandler(data);
				receivedResponse = true;
			});
			connection.onclose(() => {
				logWarn('SignalR Hub', 'Closing signalr connection');
			});
			this.connection = connection;
			this.startConnection(connection);

			setTimeout(() => {
				if (!receivedResponse) {
					this.responseDataHandler(null);
				}
			}, 5000);
		} catch (e) {
			logError(`SignalR Hub`, `Error connecting to signalr`, e);
			throw e;
		}
	}
}
