// @flow

import { type IPatientLink } from '@rs-ui/views/PatientInformationView/utils/declarations';
import { fhirExtensionUrls, getUserFullName } from '@rs-core/fhir';
import { FILE_ICON } from './constants';
import { v4 as uuidv4 } from 'uuid';
import { trimEnd } from 'lodash';
import { parseName, splitDicomPatientNameComponents } from '@rs-core/fhir/resource/columnMapping/utils/getUserFullName';
import { isValid as isValidDate, format } from 'date-fns';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const getPatientExternalId = patient =>
	patient?.identifier?.find(item => item.type?.coding?.[0].code === 'MR')?.value || '';

export const findTelecomIndex = (telecomArray, system, use) =>
	telecomArray?.findIndex(item => item.system === system && (!use || item.use === use));

export const findExtensionIndex = (extensionArray, url) => extensionArray?.findIndex(element => element.url === url);

export const isEmergencyContactValid = contact => {
	const email = contact?.telecom?.[0]?.value;
	const phone = contact?.telecom?.[1]?.value;
	const contactName = contact?.name?.text;

	return contactName && (email || phone);
};

export const prepareRelationshipOptions = (relationshipList, selectedCode = 'UNK') => {
	const selectedOption = relationshipList?.find(item => item?.code === selectedCode);

	if (selectedOption) {
		const filteredOptions = relationshipList?.filter(item => item?.code !== selectedCode);
		return [selectedOption, ...filteredOptions];
	}

	return relationshipList;
};

export const parseDocument = doc => {
	return {
		id: doc.id,
		fileName: doc.content[0].attachment.title,
		description: doc.description?.replaceAll('<br>', ' ').replaceAll('?', ''),
		extension: FILE_ICON[doc?.content?.[0]?.attachment?.contentType || 'application/pdf'],
	};
};

// Using Patient resource
export const findDifferentFields = array => {
	const fieldNames = ['Patient Name', 'Issuer of Patient ID', 'Patient ID', 'Birth Date', 'Gender'];
	const differentFields = [];

	for (const fieldName of fieldNames) {
		const valueSet = new Set();

		for (const item of array) {
			let value;

			// eslint-disable-next-line default-case
			switch (fieldName) {
				case 'Patient Name':
					value = item?.name?.[0].text;
					break;
				case 'Birth Date':
					value = item?.birthDate;
					break;
				case 'Gender':
					value = item.gender;
					break;
				case 'Patient ID':
					value = item.id;
					break;
				case 'Issuer of Patient ID':
					value = item.managingOrganization.display;
					break;
			}

			valueSet.add(value);

			if (valueSet.size > 1) {
				differentFields.push(fieldName);
				break;
			}
		}
	}

	return differentFields;
};

export const createLinkPayload = (currentPatient, patientToLink, existingLinks) => {
	const patientToLinkIssuer = patientToLink?.patient?.extension?.find(
		ext => ext.url === fhirExtensionUrls.organization.issuer
	);

	const newLink: IPatientLink = {
		other: {
			display: patientToLink?.patient?.name?.[0]?.text,
			id: patientToLink?.patientInternalID,
			reference: `Patient/${patientToLink?.patientID}`,
			identifier: patientToLink?.patient?.identifier.find(
				item =>
					item.type?.coding?.[0].system === 'http://hl7.org/fhir/ValueSet/identifier-type' &&
					item.type?.coding?.[0].code === 'MR'
			),
			extension: [
				{
					url: fhirExtensionUrls.common.managingOrganization,
					valueReference: patientToLink?.patient?.managingOrganization,
				},
				{
					url: fhirExtensionUrls.common.gender,
					valueString: patientToLink?.patient.gender,
				},
				{
					url: fhirExtensionUrls.common.birthdate,
					valueString: patientToLink?.birthDate,
				},
				{
					url: fhirExtensionUrls.organization.issuer,
					valueString: patientToLinkIssuer ? patientToLinkIssuer.valueReference.display : '',
				},
			],
		},
		type: 'seealso',
	};

	return {
		...currentPatient,
		link: existingLinks.length ? [newLink, ...existingLinks] : [newLink],
	};
};

export const splitLinkedPatientsOnParts = arr => arr.map(patient => [patient]);

export function isEmailChanged(initialPayload: any[] = [], newPayload: any[] = []): boolean {
	const initialEmails = initialPayload.filter(item => item.system === 'email');
	const newEmails = newPayload.filter(item => item.system === 'email');

	if (initialEmails.length !== 1 || newEmails.length !== 1) {
		return false;
	}

	return initialEmails[0].value !== newEmails[0].value;
}

export const extractGuarantorInfo = (entry, selfGuarantor) => {
	const { resource } = entry;

	if (!resource || resource.resourceType !== 'Account' || !resource.guarantor || resource.guarantor.length === 0) {
		return selfGuarantor;
	}
	const guarantorAssociatedParty = resource?.guarantor?.[0]?.party;
	const guarantorRelationship = guarantorAssociatedParty?.extension?.find(ext =>
		ext.url.includes('guarantorRelationship')
	);
	const phone = guarantorAssociatedParty?.extension?.find(ext => ext.url.includes(fhirExtensionUrls.account.phone));

	return {
		accountId: resource.id,
		guarantorId: guarantorAssociatedParty?.id,
		name: getUserFullName(guarantorAssociatedParty.display, true) || 'N/A',
		phoneNumber: phone ? phone.valueString : 'N/A',
		address: guarantorAssociatedParty?.address || 'N/A',
		email: guarantorAssociatedParty?.email || 'N/A',
		relationshipType: guarantorRelationship ? guarantorRelationship.valueCoding : selfGuarantor?.relationshipType,
		selfGuarantorInfo: selfGuarantor,
	};
};

export const transformContacts = contacts => {
	return contacts.map(contact => {
		const givenName = contact?.givenName ? contact.givenName?.trim().split(' ').join('^') : '';
		const text = `${contact.familyName?.trim() || ''}^${givenName}`.trim();
		const trimmedName = trimEnd(text, '^ ');
		const fhirName = {
			text: trimmedName,
			family: contact?.familyName?.trim() || '',
			given: contact?.givenName?.trim().split(' ') || '',
			prefix: [],
			suffix: [],
		};
		return {
			name: fhirName,
			telecom: [
				{
					system: 'phone',
					value: contact.phone,
				},
				{
					system: 'email',
					value: contact.email,
				},
			],
			extension: [
				{
					url: 'EmergencyContactId',
					valueString: contact.id,
				},
				{
					url: 'change-request-status',
					valueString: 'Pending',
				},
			],
			relationship: [
				{
					coding: [
						{
							system: 'http://terminology.hl7.org/CodeSystem/v2-0131',
							code: 'C',
							display: 'Emergency Contact',
						},
					],
				},
			],
		};
	});
};

// Function to parse contact name
const parseContactName = name => {
	let familyName = '';
	let givenName = '';

	if (name) {
		if (name.family === undefined && name.given === undefined && name.text) {
			// Case 1: Handle DICOM formatted name
			const { lastName, firstNameMiddle } = splitDicomPatientNameComponents(name.text);
			familyName = lastName || '';
			givenName = firstNameMiddle || '';
		} else {
			// Case 2: Handle standard name format
			const parsedName = parseName(name, true);
			if (parsedName) {
				const [lastNamePart, ...givenNameParts] = parsedName.split(', ');
				familyName = lastNamePart || '';
				givenName = givenNameParts.join(' ') || '';
			}
		}
	}

	return { familyName, givenName, text: name?.text };
};

export const reverseTransformContacts = transformedContacts => {
	return transformedContacts?.map(contact => {
		const { familyName, givenName } = parseContactName(contact.name);
		// Extract contact details
		const email = contact?.telecom?.find(t => t?.system === 'email')?.value || '';
		const phone = contact?.telecom?.find(t => t?.system === 'phone')?.value || '';
		const id = contact?.extension?.find(ext => ext?.url === 'EmergencyContactId')?.valueString || uuidv4();

		return {
			familyName,
			givenName,
			email,
			phone,
			id,
		};
	});
};

const formatDate = dateString => {
	if (!dateString) {
		return null;
	}
	const date = new Date(dateString);
	return isValidDate(date) ? format(date, 'yyyy-MM-dd') : null;
};

export const formatTelecoms = (json, showToast, t) => {
	let jsonPayload = json;
	if (jsonPayload?.telecom?.length && jsonPayload?.telecom?.some(d => d?.system === 'email')) {
		let emailTelecoms = jsonPayload?.telecom?.filter(d => d?.system === 'email');
		if (emailTelecoms?.length > 1 && emailTelecoms.every(e => !e.isPrimary)) {
			showToast(t('Please set aleast one email as primary'));
			return false;
		}
		if (emailTelecoms?.length === 1) {
			const onlyETelecom = emailTelecoms[0];
			if (onlyETelecom.extension?.length) {
				onlyETelecom.extension = onlyETelecom.extension.filter(
					d => !d?.url?.includes(fhirExtensionUrls.common.isPrimary)
				);
			} else {
				onlyETelecom.extension = [];
			}
			onlyETelecom.extension.push({
				url: fhirExtensionUrls.common.isPrimary,
				valueBoolean: true,
			});
			delete onlyETelecom.isPrimary;
			emailTelecoms = [onlyETelecom];
		} else {
			emailTelecoms = emailTelecoms.map((t, i) => {
				const updatedExtentions = t?.extension
					? t?.extension?.filter(d => !d?.url?.includes(fhirExtensionUrls.common.isPrimary))
					: [];
				if (t.isPrimary)
					updatedExtentions.push({
						url: fhirExtensionUrls.common.isPrimary,
						valueBoolean: true,
					});
				return {
					...t,
					extension: updatedExtentions,
				};
			});
		}
		jsonPayload = {
			...jsonPayload,
			telecom: [...jsonPayload.telecom.filter(d => d.system !== 'email'), ...emailTelecoms],
		};
	}
	return jsonPayload;
};

interface IFormValuesType {
	[key: string]: any;
}

export const createUpdatePatientPayload = (formValues: IFormValuesType, patientClass) => {
	const settersMapping = {
		familyName: value => patientClass.setPatientFamilyName(value?.trim() || ''),
		givenNames: value => patientClass.setPatientGivenNames(value?.trim() || ''),
		prefix: value => patientClass.setPatientNamePrefix(value?.trim() || ''),
		suffix: value => patientClass.setPatientNameSuffix(value?.trim() || ''),
		ssn: value => patientClass.setPatientSSN(value),
		driversLicense: value => patientClass.setPatientDriverLicense(value),
		patientId: value => patientClass.setPatientIdentifier(value),
		maritalStatus: value => patientClass.setPatientMaritalStatus(value),
		birthSex: value => patientClass.setPatientBirthSex(value),

		language: value => patientClass.setPatientLanguage(value),
		race: value => patientClass.setPatientRace(value),
		ethnicity: value => patientClass.setPatientEthnicity(value),
		confidentiality: value => patientClass.setPatientConfidentiality(value),
		gender: value => patientClass.setPatientGender(value),
		telecom: value => patientClass.setPatientTelecom(value),
		address: value => patientClass.setPatientAddress(value),
		mothersMaidenName: value => patientClass.setPatientMothersMaidenName(value),
		mothersGivenName: value => patientClass.setPatientMothersGivenName(value),
		fathersFamilyName: value => patientClass.setPatientFathersMaidenName(value),
		fathersGivenNames: value => patientClass.setPatientFathersGivenName(value),
	};

	// Process common fields
	Object.entries(settersMapping).forEach(([field, setter]) => {
		if (field in formValues) {
			setter(formValues[field]);
		}
	});

	// Handle special cases
	if ('birthDate' in formValues) {
		patientClass.setPatientBirthDate(formatDate(formValues.birthDate));
	}
	if ('deceasedDateTime' in formValues) {
		patientClass.setPatientDeceasedDateTime(formatDate(formValues.deceasedDateTime));
	}
	return patientClass.patientPayload;
};

export const getPatientInfoAsDefaultValues = patientClass => {
	if (!patientClass) return {};
	const { familyName: mothersMaidenName, givenName: mothersGivenName } = parseContactName(
		patientClass.getPatientMothersMaidenName()
	);
	const { familyName: fathersMaidenName, givenName: fathersGivenName } = parseContactName(
		patientClass.getPatientFathersMaidenName()
	);
	return {
		familyName: patientClass.getPatientFamilyName(),
		givenNames: patientClass.getPatientGivenNames(),
		prefix: patientClass.getPatientNamePrefix(),
		suffix: patientClass.getPatientNameSuffix(),
		ssn: patientClass.getPatientSSN(),
		patientId: patientClass.getPatientIdentifier(),
		driversLicense: patientClass.getPatientDriverLicense(),
		birthDate: patientClass.getPatientBirthDate(),
		gender: patientClass.getPatientGender(),
		birthSex: patientClass.getPatientBirthSex(),
		managingOrganization: patientClass.getPatientManagingOrganization()?.display,
		maritalStatus: patientClass.getPatientMaritalStatus(),
		language: patientClass.getPatientLanguage(),
		race: patientClass.getPatientRace(),
		ethnicity: patientClass.getPatientEthnicity(),
		confidentiality: patientClass.getPatientConfidentiality(),
		deceasedDateTime: patientClass.getPatientDeceasedDateTime(),
		mothersMaidenName: mothersMaidenName,
		mothersGivenName: mothersGivenName,
		fathersFamilyName: fathersMaidenName,
		fathersGivenNames: fathersGivenName,
	};
};

export const getPatientTelecomsAsDefaultValues = patientClass => {
	if (!patientClass?.patient?.telecom) return { address: [], telecom: [] };

	const telecoms = patientClass.patient.telecom.map(item => ({
		...item,
		isPrimary:
			item.system === 'email' &&
			item.extension?.some(
				ext => ext.url?.includes(fhirExtensionUrls.common.isPrimary) && ext.valueBoolean === true
			),
	}));

	const sortedTelecoms = [
		...telecoms.filter(t => t.system === 'email' && t.isPrimary),
		...telecoms.filter(t => !(t.system === 'email' && t.isPrimary)),
	];

	return {
		address: patientClass.patient?.address || [],
		telecom: sortedTelecoms,
	};
};

export const filterContactsByType = (contacts: Array<ContactType>, type: string): Array<ContactType> =>
	contacts?.filter(contact => contact?.system === type) || [];

export const getPrimaryContacts = (contacts: Array<ContactType>): Array<ContactType> => {
	const primaryContacts = contacts.filter(contact =>
		contact?.extension?.some(
			ext => ext?.url?.includes(fhirExtensionUrls.common.isPrimary) && ext.valueBoolean === true
		)
	);
	return primaryContacts.length ? primaryContacts : contacts;
};

export const getPhoneNumber = telecom => telecom?.find(item => item.system === 'phone')?.value || 'N/A';

export const getEmail = telecom => {
	if (!telecom || !Array.isArray(telecom)) return 'N/A';

	const emails = telecom.filter(item => item.system === 'email');
	if (emails.length === 0) return 'N/A';

	const primaryEmails = getPrimaryContacts(emails);
	return primaryEmails[0]?.value || 'N/A';
};

export const getAddress = ({ line = [], city = '', state = '', country = '' } = {}) => {
	const parts = [line[0], city, state, country].filter(Boolean);
	return parts.length ? parts.join(', ') : '';
};

export const formatPhoneNumber = (value: string): string => {
	try {
		return parsePhoneNumberFromString(value)?.formatInternational() || value;
	} catch {
		return value;
	}
};
