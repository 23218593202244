import { getStudyInstanceUID, getSeriesUID } from '../utils/utils';
import { useImageViewerMeasurementContext } from '../contexts/ImageViewerMeasurementContext';
import loadSeriesWithMetadata from '../api/loadSeriesWithMetadata';
import { useConfig, logDebug, logError } from '@worklist-2/core/src';
import { useCache } from '@rs-core/context/CacheContext';
import { useImageViewerStudiesContext } from '../contexts/ImageViewerStudiesContext';
import { getKeyImages } from '../api/keyImage';
import { useImageViewerView3DContext } from '../contexts/ImageViewerView3DContext';
import { BROADCAST_EVENTS } from '../contexts/consts/consts';
import { useImageViewerMultiMonitorContext } from '../contexts/ImageViewerMultiMonitorContext';
import { useSearchParams } from 'react-router-dom';
import { useBooleanFlagValue, useNumberFlagValue } from '@rs-core/hooks/useFlags';

export const useLoadStudyData = () => {
	const __config = useConfig();
	const wonIvSeriesSplitter = useBooleanFlagValue('won-iv-series-splitter');
	const wonIvPlaneSplitter = useBooleanFlagValue('won-iv-plane-splitter');
	const wonIvDisablexasplit = useBooleanFlagValue('WON-IV-DISABLEXASPLIT');
	const wonIvSeriesSplitterUpperLimit = useNumberFlagValue('won-iv-series-splitter-upper-limit');
	const wonIvEnhancedcurvedsort = useBooleanFlagValue('WON-IV-ENHANCEDCURVEDSORT');
	const wonIvStillsfirst = useBooleanFlagValue('WON-IV-STILLSFIRST');
	const wonIvRefactorsorting = useBooleanFlagValue('WON-IV-REFACTORSORTING');
	const { cacheLinks } = useCache();

	const { loadingManagerRef } = useImageViewerView3DContext();
	const { addNewSeries, onSetKeyImages, setSeries, seriesRef } = useImageViewerStudiesContext();
	const { measurementLoader, loadMeasurements } = useImageViewerMeasurementContext();
	const { postMessage } = useImageViewerMultiMonitorContext();

	const [searchParams] = useSearchParams();
	const creatorUserId = searchParams.get('creatorBlumeId') || null;

	const refreshStudiesSeriesByTask = async ({ studies, task }) => {
		const studiesNeedReload = [
			{
				from: true,
				studyInstanceUID: task.operationFrom.study,
				managingOrganizationId: task.operationFrom.orgId.toString(),
				internalStudyId: studies.find(
					study =>
						getStudyInstanceUID(study) === task.operationFrom.study &&
						study.managingOrganizationId === task.operationFrom.orgId.toString()
				)?.internalStudyId,
			},
			{
				to: true,
				studyInstanceUID: task.operationTo.study,
				managingOrganizationId: task.operationTo.orgId.toString(),
				internalStudyId: studies.find(
					study =>
						getStudyInstanceUID(study) === task.operationFrom.study &&
						study.managingOrganizationId === task.operationFrom.orgId.toString()
				)?.internalStudyId,
			},
		];

		loadStudiesMeasurements({ studies });
		await loadStudiesKeyImages({ studies });

		const studiesObjArr = studies
			.map(study => ({
				studyInstanceUID: getStudyInstanceUID(study),
				internalStudyId: study.internalStudyId,
				managingOrganizationId: study.managingOrganizationId,
			}))
			.filter(study =>
				studiesNeedReload.some(
					st =>
						st.studyInstanceUID === study.studyInstanceUID &&
						st.managingOrganizationId === study.managingOrganizationId
				)
			);

		const promises = studiesObjArr.map(async study => {
			loadingManagerRef.current.studySeries[study.studyInstanceUID] = true;
			try {
				return await loadSeriesWithMetadata({
					__config,
					managingOrganizationId: study.managingOrganizationId,
					studyInstanceUID: study.studyInstanceUID,
					wonIvSeriesSplitter,
					wonIvDisablexasplit,
					wonIvSeriesSplitterUpperLimit,
					wonIvEnhancedcurvedsort,
					wonIvRefactorsorting,
					wonIvStillsfirst,
					cacheLinks,
					wonIvPlaneSplitter,
					is3D: true,
					creatorUserId,
					internalStudyId: study.internalStudyId,
				});
			} catch (error) {
				logError('IV::', 'Error loading series for study');
				loadingManagerRef.current.studySeries[study.studyInstanceUID] = false;
				return []; // Return an empty array on error to avoid breaking Promise.all
			}
		});

		const seriesBatch = await Promise.all(promises);
		const seriesUpdated = [];

		seriesBatch.forEach(item => seriesUpdated.push(...item));

		// FIlter series, get only affected ones by reparent
		const toItem = studiesNeedReload.find(item => item.to);
		const affectedSeriesUids = task.operationFrom.series.split(',');
		const affectedSeriesUpdated = seriesUpdated.filter(
			seriesItem =>
				affectedSeriesUids.includes(getSeriesUID(seriesItem)) &&
				toItem.studyInstanceUID === seriesItem.studyInstanceUID
		);

		if (!affectedSeriesUpdated.length) {
			return;
		}

		const filterSeries = [
			...seriesRef.current.filter(item => !affectedSeriesUids.includes(getSeriesUID(item))),
			...affectedSeriesUpdated,
		];

		setSeries(filterSeries);

		postMessage({
			event: BROADCAST_EVENTS.STUDY_SERIES_UPDATE,
			value: filterSeries,
		});
	};

	const loadStudiesSeries = async ({ studies, studiesObjArr: sObjArr }) => {
		const studiesObjArr =
			sObjArr ||
			studies.map(study => ({
				studyInstanceUID: getStudyInstanceUID(study),
				internalStudyId: study.internalStudyId,
				managingOrganizationId: study.managingOrganizationId,
			}));

		const promises = studiesObjArr
			.filter(study => !loadingManagerRef.current.studySeries[study.studyInstanceUID])
			.map(async study => {
				loadingManagerRef.current.studySeries[study.studyInstanceUID] = true;

				try {
					const loadedSeries = await loadSeriesWithMetadata({
						__config,
						managingOrganizationId: study.managingOrganizationId,
						studyInstanceUID: study.studyInstanceUID,
						wonIvSeriesSplitter,
						wonIvDisablexasplit,
						wonIvSeriesSplitterUpperLimit,
						wonIvEnhancedcurvedsort,
						wonIvRefactorsorting,
						wonIvStillsfirst,
						cacheLinks,
						wonIvPlaneSplitter,
						is3D: true,
						creatorUserId,
						internalStudyId: study.internalStudyId,
					});
					return loadedSeries;
				} catch (error) {
					logError('IV::', 'Error loading series for study', {
						studyInstanceUID: study.studyInstanceUID,
						error,
					});
					loadingManagerRef.current.studySeries[study.studyInstanceUID] = false;
					return []; // Return an empty array on error to avoid breaking Promise.all
				}
			});

		const seriesBatch = await Promise.all(promises);
		// eslint-disable-next-line no-shadow
		const series = [];

		seriesBatch.forEach(item => series.push(...item));

		if (!series.length) {
			return;
		}

		logDebug('IV::', `Total Series Loaded`, { num: series.length });

		addNewSeries(series);
		postMessage({
			event: BROADCAST_EVENTS.STUDY_SERIES_UPDATE,
			value: series,
		});
	};

	const loadStudiesMeasurements = ({ studies, studiesObjArr: sObjArr }) => {
		const studiesObjArr =
			sObjArr ||
			studies.map(study => ({
				studyInstanceUID: getStudyInstanceUID(study),
				managingOrganizationId: study.managingOrganizationId,
			}));
		studiesObjArr
			.filter(study => !loadingManagerRef.current.studyMeasurements[study.studyInstanceUID])
			.forEach(study => {
				loadingManagerRef.current.studyMeasurements[study.studyInstanceUID] = true;

				loadMeasurements(measurementLoader, {
					StudyInstanceUID: study.studyInstanceUID,
					InternalManagingOrgId: study.managingOrganizationId,
				});
			});
	};

	const loadStudiesKeyImages = async ({ studies, studiesObjArr: sObjArr }) => {
		const studiesObjArr =
			sObjArr ||
			studies.map(study => ({
				studyInstanceUID: getStudyInstanceUID(study),
				managingOrganizationId: study.managingOrganizationId,
			}));
		const promises = studiesObjArr
			.filter(study => !loadingManagerRef.current.studyKeyImages[study.studyInstanceUID])
			.map(async study => {
				loadingManagerRef.current.studyKeyImages[study.studyInstanceUID] = true;

				return getKeyImages({
					__config,
					studyInstanceUID: study.studyInstanceUID,
					internalManagingId: study.managingOrganizationId,
				});
			});

		const keyImages = [];
		const keyImagesBatch = await Promise.all(promises);

		keyImagesBatch.forEach(item => keyImages.push(...item));

		if (!keyImages.length) {
			return;
		}

		await onSetKeyImages(prevState => [...prevState, ...keyImages]);
	};

	const loadStudiesData = async ({ studies, studiesObjArr, callback }) => {
		await loadStudiesSeries({ studies, studiesObjArr });
		if (typeof callback === 'function') {
			callback();
		}
		loadStudiesMeasurements({ studies, studiesObjArr });
		await loadStudiesKeyImages({ studies, studiesObjArr });
	};

	return {
		loadStudiesData,
		loadStudiesSeries,
		refreshStudiesSeriesByTask,
		loadStudiesMeasurements,
		loadStudiesKeyImages,
	};
};

export default useLoadStudyData;
