import { calculateBMI, calculateBMIStatus } from '@rs-ui/views/PatientInformationView/utils/bmiUtils';

// observation codes
const vitalCodes = {
	bodyHeight: '8302-2',
	bodyWeight: '29463-7',
	heartRate: '8867-4',
	diastolicBlood: '8462-4',
	systolicBlood: '8480-6',
	bloodPressure: '85354-9',
};
const alcoholCode = '69643-7';
const smokingCode = '72166-2';
const pregnancyCode = '82810-3';

class Observations {
	constructor(observations = []) {
		this.observations = observations;
		this.vitalObservations = {};
		this.alcoholObservations = {};
		this.smokingObservations = {};
		this.pregnancyObservations = {};

		// Automatically set observations when the class is instantiated
		this.setPatientObservations(this.observations);
	}

	// #region =============   getter Functions   ============
	getVitalObservations = () => this.vitalObservations;

	getSmokingObservations = () => this.smokingObservations;

	getAlcoholObservations = () => this.alcoholObservations;

	getPregnancyObservations = () => this.pregnancyObservations;
	// #endregion

	// #region =============   setter Functions   ============
	setPatientObservations = (observations = []) => {
		const vitals = {};

		observations.forEach(element => {
			const resource = element?.resource;
			const observationCode = resource?.code?.coding?.[0]?.code;

			switch (observationCode) {
				case vitalCodes.bodyHeight:
					vitals.bodyHeight = resource?.valueQuantity?.value;
					break;

				case vitalCodes.bodyWeight:
					vitals.bodyWeight = resource?.valueQuantity?.value;
					break;

				case vitalCodes.heartRate:
					vitals.heartRate = resource?.valueQuantity?.value;
					break;

				case vitalCodes.bloodPressure:
					const diastolicComponent = resource?.component?.find(
						el => el?.code?.coding?.[0]?.code === vitalCodes.diastolicBlood
					);
					const systolicComponent = resource?.component?.find(
						el => el?.code?.coding?.[0]?.code === vitalCodes.systolicBlood
					);

					if (diastolicComponent && systolicComponent) {
						vitals.diastolicBlood = diastolicComponent?.valueQuantity?.value;
						vitals.systolicBlood = systolicComponent?.valueQuantity?.value;
					}
					break;

				case alcoholCode:
					this.alcoholObservations = {
						code: resource?.valueCodeableConcept?.coding?.[0]?.code,
						display: resource?.valueCodeableConcept?.coding?.[0]?.display,
					};
					break;

				case smokingCode:
					this.smokingObservations = {
						code: resource?.valueCodeableConcept?.coding?.[0]?.code,
						display: resource?.valueCodeableConcept?.coding?.[0]?.display,
					};
					break;

				case pregnancyCode:
					this.pregnancyObservations = {
						code: resource?.valueCodeableConcept?.coding?.[0]?.code,
						display: resource?.valueCodeableConcept?.coding?.[0]?.display,
					};
					break;

				default:
					break;
			}
		});

		this.vitalObservations = {
			...vitals,
			bmi: vitals.bodyWeight && vitals.bodyHeight && calculateBMIStatus(vitals.bodyWeight, vitals.bodyHeight),
			bmiValue: vitals.bodyWeight && vitals.bodyHeight && calculateBMI(vitals.bodyWeight, vitals.bodyHeight),
		};
	};
	// #endregion
}

export default Observations;
