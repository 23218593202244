import updateSynchronizedScreen from './updateSynchronizedScreen';
import { logDebug } from '@worklist-2/core/src';

const addSynchronizedScreen = ({ synchronizedScreens, newScreen }) => {
	logDebug('IV::Multi-Monitor', 'addSynchronizedScreen', { synchronizedScreens, newScreen });
	const screenNumberIndex = synchronizedScreens.current.findIndex(
		synchronizedScreen => synchronizedScreen.screenNumber === newScreen.screenNumber
	);

	if (screenNumberIndex !== -1) {
		return updateSynchronizedScreen({ synchronizedScreens, updatedScreen: newScreen });
	}

	synchronizedScreens.current.push(newScreen);
};

export default addSynchronizedScreen;
