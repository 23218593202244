// This file was not generated.
import dateTimeUtil from '@rs-core/utils/dateTimeUtil';
import { formatDuration } from './utils';

const FaxMapping = {
	id: {
		label: 'Id',
		options: {
			filter: false,
			sort: false,
			display: 'excluded',
			viewColumns: false,
			excludeFromSave: true,
		},
		queryParameter: 'id',
		valueSet: {},
		filterType: 'none',
		getDisplay: resource => {
			return resource.id;
		},
	},
	status: {
		label: 'Status',
		options: {
			filter: true,
			sort: false,
			setCellProps: () => ({
				style: { minWidth: '170px' },
			}),
		},
		searchParameter: 'status',
		searchValueSet: 'faxStatus',
		filterType: 'multi-select',
		getDisplay: resource => {
			return resource.status;
		},
	},
	faxDatetime: {
		label: 'Fax Date/Time',
		options: {
			filter: true,
			sort: true,
		},
		searchParameter: 'faxdatetime',
		valueSet: {},
		filterType: 'date-range',
		getDisplay: resource => {
			const formatedDateTime = dateTimeUtil.parseLocaleDate(resource.faxDatetime, 'dateTimeShort');
			return formatedDateTime;
		},
	},
	faxDirection: {
		label: 'Direction',
		options: {
			filter: true,
			sort: false,
		},
		searchParameter: 'faxDirection',
		searchValueSet: 'faxDirection',
		filterType: 'single-select',
		getDisplay: resource => {
			return resource.faxDirection;
		},
	},
	faxFrom: {
		label: 'From',
		options: {
			filter: true,
			sort: true,
		},
		searchParameter: 'faxFrom',
		valueSet: {},
		filterType: 'text-search',
		getDisplay: resource => {
			return resource.faxFrom;
		},
	},
	faxNumber: {
		label: 'To',
		options: {
			filter: true,
			sort: true,
		},
		searchParameter: 'faxNumber',
		valueSet: {},
		filterType: 'text-search',
		getDisplay: resource => {
			return resource.faxNumber;
		},
	},
	pages: {
		label: 'Pages',
		options: {
			filter: false,
			sort: false,
		},
		getDisplay: resource => {
			return resource.pages;
		},
	},
	duration: {
		label: 'Duration',
		options: {
			filter: false,
			sort: false,
		},
		getDisplay: resource => {
			return formatDuration(resource.duration);
		},
	},
	patient: {
		label: 'Patient',
		options: {
			filter: true,
			sort: false,
		},
		searchParameter: 'patient',
		valueSet: {},
		filterType: 'text-search',
		getDisplay: resource => {
			return resource.patient_display;
		},
	},
	study: {
		label: 'Study',
		options: {
			filter: true,
			sort: false,
		},
		searchParameter: 'study',
		valueSet: {},
		filterType: 'text-search',
		getDisplay: resource => {
			return resource.study_display;
		},
	},
	failedReason: {
		label: 'Failed Reason',
		options: {
			filter: false,
			sort: false,
		},
		getDisplay: resource => {
			return resource.failedReason;
		},
	},
	organizationName: {
		label: 'Managing Organization',
		options: {
			filter: true,
			sort: false,
		},
		searchParameter: 'organizationid',
		filterType: 'suggest',
		suggestScope: {
			resource: 'organization',
			label: 'name',
			extraParam: {
				ismanaging: true,
				active: true,
				_summary: 'text',
				_elements: 'name',
			},
		},
		getDisplay: resource => {
			return resource.rawData.organizationName || resource.rawData.organization?.display;
		},
	},
	accessionNumber: {
		label: 'Accession #',
		options: {
			filter: true,
			sort: true,
		},
		searchParameter: 'accessionNumber',
		valueSet: {},
		filterType: 'text-search',
		getDisplay: resource => {
			return resource.rawData?.accessionNumber;
		},
	},
	patientId: {
		label: 'Patient ID',
		options: {
			filter: true,
			sort: true,
		},
		searchParameter: 'patientId',
		valueSet: {},
		filterType: 'text-search',
		getDisplay: resource => {
			return resource.patient_id;
		},
	},
};
export default FaxMapping;
