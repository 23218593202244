import React, { createContext, useEffect, useRef } from 'react';
import { createStore as createZustandStore } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { getZustandStoreBroadcastChannelName } from './utils/zustand/getZustandStoreBroadcastChannelName';
import useZustandStoreSelector from './utils/zustand/useZustandStoreSelector';
import useZustandStore from './utils/zustand/useZustandStore';
import { zustandShare } from './utils/zustand/useZustandShare';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const createStore = () =>
	createZustandStore(
		subscribeWithSelector(set => ({
			scoutLines: true,
			setScoutLines: scoutLines => set({ scoutLines }),
			toggleScoutLines: () => set(state => ({ scoutLines: !state.scoutLines })),
		}))
	);

const useStoreSync = storeRef => {
	useEffect(() => {
		const storeName = 'scoutLinesStore';
		const storeBroadcastChannelName = getZustandStoreBroadcastChannelName(storeName);
		const options = {
			initialize: true,
			ref: storeBroadcastChannelName,
		};

		const [, unsubscribe] = zustandShare('scoutLines', storeRef.current, options);

		return () => unsubscribe();
	}, [storeRef]);
};

const ScoutLinesContext = createContext(null);

const ScoutLinesContextProvider = ({ children }) => {
	const storeRef = useRef();

	if (!storeRef.current) {
		storeRef.current = createStore();
	}

	useStoreSync(storeRef);

	return <ScoutLinesContext.Provider value={storeRef.current}>{children}</ScoutLinesContext.Provider>;
};

const useScoutLinesStore = () => useZustandStore(ScoutLinesContext);

const useScoutLinesStoreSelector = selector => useZustandStoreSelector(selector, ScoutLinesContext);

export { ScoutLinesContext, ScoutLinesContextProvider, useScoutLinesStore, useScoutLinesStoreSelector };
