import { logDebug } from '@rs-core/index';
import { compressObjectToArrayBuffer, decompressArrayBufferToObject } from '@rs-core/utils';

const zustandShare = (key, api, { ref = 'shared-', initialize = false } = {}) => {
	const channelName = `${ref}-${key.toString()}`;

	const channel = new BroadcastChannel(channelName);
	let externalUpdate = false;
	let timestamp = 0;

	const cleanup = api.subscribe(
		state => state[key],
		state => {
			if (!externalUpdate) {
				timestamp = Date.now();
				const message = { timestamp, state };
				const compressedBuffer = compressObjectToArrayBuffer(message);
				channel.postMessage(compressedBuffer);
			}
			externalUpdate = false;
		}
	);

	channel.onmessage = evt => {
		logDebug('useZustandShare', `channel.onmessage - KEY: ${key} - Start`, { timestamp: Date.now() });
		if (evt.data === undefined) {
			const message = { timestamp, state: api.getState()[key] };

			if (timestamp !== 0) {
				const compressedBuffer = compressObjectToArrayBuffer(message);
				channel.postMessage(compressedBuffer);
			}

			return;
		}

		let message = evt.data;
		// decompress message after receiving
		message = decompressArrayBufferToObject(evt.data);

		if (message.timestamp <= timestamp) {
			return;
		}

		if (api.getState()[key] === message.state) {
			return;
		}

		externalUpdate = true;
		timestamp = message.timestamp;

		api.setState({ [key]: message.state });
	};

	const sync = () => channel.postMessage(undefined);

	const unshare = () => {
		cleanup();
		channel.close();
	};

	if (initialize) {
		sync();
	}

	return [sync, unshare];
};

export { zustandShare };
