import React, { createContext, useEffect, useRef } from 'react';
import { createStore as createZustandStore } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { getZustandStoreBroadcastChannelName } from '../../../../contexts/utils/zustand/getZustandStoreBroadcastChannelName';
import useZustandStoreSelector from '../../../../contexts/utils/zustand/useZustandStoreSelector';
import useZustandStore from '../../../../contexts/utils/zustand/useZustandStore';
import { zustandShare } from '../../../../contexts/utils/zustand/useZustandShare';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const createStore = () =>
	createZustandStore(
		subscribeWithSelector((set, get) => ({
			propagatedTool: {},
			setPropagatedTool: propagatedTool =>
				set({ propagatedTool: { toolName: propagatedTool, timeStamp: Date.now() } }),
			tools: {},
			setTools: tools => set({ tools }),
			activeTool: null,
			setActivateTool: activeTool => {
				set({ activeTool: { toolName: activeTool, timeStamp: Date.now() } });
			},
			hotkeyConfig: null,
			setHotkeyConfig: hotkeyConfig => set({ hotkeyConfig }),
			availableExtraTools: {},
			setAvailableExtraTools: newTools => {
				const currentTools = get().availableExtraTools;
				set({ availableExtraTools: { ...currentTools, ...newTools } });
			},
		}))
	);

const useStoreSync = storeRef => {
	useEffect(() => {
		const storeName = 'imageViewerToolbarStore';
		const storeBroadcastChannelName = getZustandStoreBroadcastChannelName(storeName);
		const options = {
			initialize: true,
			ref: storeBroadcastChannelName,
		};

		const [, unsubscribeAvailableExtraTools] = zustandShare('availableExtraTools', storeRef.current, options);
		const [, unsubscribeActiveTool] = zustandShare('activeTool', storeRef.current, options);
		const [, unsubscribeHotkeyConfig] = zustandShare('hotkeyConfig', storeRef.current, options);
		const [, unsubscribeTools] = zustandShare('tools', storeRef.current, options);
		const [, unsubscribePropagatedTool] = zustandShare('propagatedTool', storeRef.current, options);

		return () => {
			unsubscribeAvailableExtraTools();
			unsubscribeActiveTool();
			unsubscribeHotkeyConfig();
			unsubscribeTools();
			unsubscribePropagatedTool();
		};
	}, [storeRef]);
};

const CustomToolBarContext = createContext(null);

const CustomToolBarZustandProvider = ({ children }) => {
	const storeRef = useRef();

	if (!storeRef.current) {
		storeRef.current = createStore();
	}

	useStoreSync(storeRef);

	return <CustomToolBarContext.Provider value={storeRef.current}>{children}</CustomToolBarContext.Provider>;
};

const useCustomToolBarStore = () => useZustandStore(CustomToolBarContext);

const useCustomToolBarStoreSelector = selector => useZustandStoreSelector(selector, CustomToolBarContext);

export { CustomToolBarContext, CustomToolBarZustandProvider, useCustomToolBarStore, useCustomToolBarStoreSelector };
